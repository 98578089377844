import { Link } from "gatsby"
import React from "react"

const Error = () => {
  return (
    <div className="max-w-7xl my-48 mx-auto px-4 sm:px-6 lg:px-8">
      <div className="lg:text-center">
        <h2 className="text-4xl text-red-600 font-semibold tracking-wide uppercase">
          404
        </h2>
        <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Errore nella pagina richiesta
        </p>
        <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
          Controllare la pagina richiesta e riprovare, oppure tornare alla{" "}
          <Link to="/" className="underline underline-offset-2 text-sky-500">
            pagina principale
          </Link>
        </p>
      </div>
    </div>
  )
}

export default Error
